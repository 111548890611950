import * as React from 'react'
import { Script } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

// Utils
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { linkResolver } from './src/utils/linkResolver'

import '@fontsource/montserrat/300.css'
import '@fontsource/montserrat/500.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/montserrat/700.css'

import '@fontsource/jost/400.css'
import '@fontsource/jost/700.css'
import '@fontsource/jost/900.css'

import './src/styles/default.css'

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    externalLinkComponent={(props) => {
      const childProps = { ...props }
      delete childProps.activeClassName
      childProps.target = props.target || '_blank'
      childProps.rel = props.rel || 'noopener noreferrer'
      return (
        <a data-location='external' {...props}>
          {props.children}
        </a>
      )
    }}>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
  </PrismicProvider>
)

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <link href={`https://static.${process.env.GATSBY_BIDJS_DOMAIN}/${process.env.GATSBY_BIDJS_VERSION}/bootstrap3/css/bidjs--full.min.css`} rel='stylesheet' type='text/css' />
      <Script id='bid-js-script' src={`https://static.${process.env.GATSBY_BIDJS_DOMAIN}/${process.env.GATSBY_BIDJS_VERSION}/bootstrap3/js/bidjs.min.js`} />
    </>
  )
}
