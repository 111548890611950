exports.linkResolver = (doc) => {
  var lang = ''
  if (doc.lang !== 'en-gb') {
    lang = doc.lang?.slice(0, 2) + '/'
  }
  if (doc.uid !== 'index' && doc.type === 'page') return `/${lang}${doc.uid}/`
  if (doc.uid === 'index' && doc.type === 'page') return `/${lang}`
  if (doc.type === 'blogpost') return `/${lang}news/${doc.uid}/`
  return '/'
}
